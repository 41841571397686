import React, { useEffect, useRef, useState } from 'react';
import { Checkbox, Menu, MenuItem, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import { DateRangePicker } from 'materialui-daterange-picker';
import Text from 'modules/Text';
import moment from 'moment';

import api_requests from 'resources/api_requests';
import ImageLinks from 'assets/images/ImageLinks';
import utils from 'resources/utils';
import FirstTimeOverlay from 'modules/FirstTimeOverlay';
import theme from 'resources/theme';
import Button from 'modules/Button';
import constant from 'resources/constant';
import NotificationService from 'resources/NotificationService';
import Overview from './components/Overview';
import TotalOrders from './components/TotalOrders';
import ChannelMix from './components/ChannelMix';
import Revenue from './components/Revenue';
import OrderZones from './components/OrderZones';
import DeliveryPartner from './components/DeliveryPartner';
import Select from 'modules/Select';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import CouponCode from 'modules/modals/CouponCode';
import { useHistory } from 'react-router-dom';
import Routes from 'resources/Routes';

const dropdown_menu = {
	created_at: { value: 'created_at', label: 'Creation Date' },
	pickup_at: { value: 'pickup_at', label: 'Pickup Date' },
	delivery_at: { value: 'delivery_at', label: 'Delivery Date' },
};

const useStyles = makeStyles((theme) => ({
	main_container: { padding: 32 },
	flex_box: { display: 'flex', gap: 20 },
	title_box: { display: 'flex', justifyContent: 'space-between', marginTop: 30 },
	divider: { height: 1, width: '100%', backgroundColor: theme.colors.lightGrey5 },
	calender_icon: { height: 20, width: 20, backgroundColor: theme.colors.lightPurple, borderRadius: 10 },
	date_text: { color: theme.colors.primary, fontSize: 16 },
	get_strt_btn: {
		width: 90,
		height: 32,
		borderRadius: 30,
		padding: '8px 12px',
		cursor: 'pointer',
	},
	list_text: { backgroundColor: theme.colors.lightPurple9, borderRadius: 50, padding: '2px 8px', display: 'inline-flex', gap: 5, cursor: 'pointer' },
	global_header_text: {
		fontSize: 20,
		color: '#1e1e1e',
		lineHeight: '36px',
	},
	coupon_wrapper: {
		backgroundColor: theme.colors.white,
		borderRadius: 4,
		border: `1px solid ${theme.colors.lightGrey5}`,
		boxShadow: `0px 2px 4px 0px rgba(196, 196, 196, 0.30)`,
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 8,
		paddingRight: 8,
	},
	coupon_text: { fontSize: 12, marginLeft: 4, marginRight: 4 },
	wallet_text: { fontSize: 12, color: theme.colors.primary, textDecoration: 'underline', marginRight: 4, cursor: 'pointer' },
}));

function Dashboard() {
	const classes = useStyles();
	const { selected_business_account, enterprise_child_business_data, app_configs } = useSelector((state) => state.app, shallowEqual);
	const [data, set_data] = useState({});
	const [date_picker_open, set_date_picker_open] = useState(false);
	const [is_first_time_user, set_is_first_time_user] = useState(false);
	const [get_started_clicked, set_get_started_clicked] = useState(false);
	const [selected_menu, set_selected_menu] = useState(dropdown_menu.created_at.value);
	const [show_dropdown, set_show_dropdown] = useState(false);
	const [selected_business, set_selected_business] = useState([]);
	const [all_selected, set_all_selected] = useState(false);
	const [component_loader, set_component_loader] = useState(true);
	const [coupon_data, set_coupon_data] = useState({});
	const [show_first_time_coupon_modal, set_show_first_time_coupon_modal] = useState(false);
	const menu_ref = useRef(null);
	const history = useHistory();
	const is_initial_render = useRef(true);

	const owner_type = utils._retrieveData(constant.TYPE_OF_OWNER);

	const [filters, set_filters] = React.useState({
		from: moment(moment().subtract('29', 'days').format('MM-DD-YYYY')),
		to: moment(moment().format('MM-DD-YYYY')),
	});

	useEffect(() => {
		const new_user = localStorage.getItem('new_user');
		if (new_user === 'true') {
			set_is_first_time_user(true);
			set_get_started_clicked(true);
		}
		if (!_.isEmpty(_.get(app_configs, 'landing.coupon', {}))) {
			const coupon = _.get(app_configs, 'landing.coupon', {});
			const created_at = coupon?.created_at;
			const today_date = moment();
			const created_at_moment = moment(created_at);
			const days_diff = today_date.diff(created_at_moment, 'days');
			if (days_diff <= 4) {
				set_coupon_data(app_configs.landing?.coupon);
				set_show_first_time_coupon_modal(true);
			}
		}
		NotificationService.request_permission();
	}, []);

	const get_data = async (owner_ids = []) => {
		try {
			const res = await api_requests.get_dashboard_analytics({
				...(selected_menu === 'created_at' && { created_at: `${filters.from.format('YYYY-MM-DD')},${filters.to.format('YYYY-MM-DD')}` }),
				...(selected_menu === 'pickup_at' && { pickup_at: `${filters.from.format('YYYY-MM-DD')},${filters.to.format('YYYY-MM-DD')}` }),
				...(selected_menu === 'delivery_at' && { delivery_at: `${filters.from.format('YYYY-MM-DD')},${filters.to.format('YYYY-MM-DD')}` }),
				group: 'all',
				...(!_.isEmpty(owner_ids) && { owner_ids: owner_ids?.join(', ') }),
			});

			if (!_.isEmpty(res.data)) {
				set_data(res.data);
				set_component_loader(false);
			}
		} catch (err) {}
	};

	const get_start_tap = () => {
		localStorage.setItem('new_user', false);
		set_is_first_time_user(false);
		set_get_started_clicked((prev) => !prev);
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.CLICK_GET_STARTED);
	};

	const handle_wallet_click = () => {
		history.push(Routes.MY_WALLET.path);
	};

	const handle_select_all = () => {
		if (all_selected) {
			set_selected_business([selected_business_account?.business_id]);
		} else {
			const all_business = _.map(enterprise_child_business_data, 'id');
			all_business.push(selected_business_account?.business_id);
			set_selected_business(all_business);
		}
	};
	const is_all_selected = () => {
		let flag = true;
		const ids = _.map(enterprise_child_business_data, 'id');
		_.map(ids, (id) => {
			if (!_.includes(selected_business, id) && id !== selected_business_account?.business_id) {
				flag = false;
			}
		});
		return flag;
	};
	const handle_select_drop_down = (id) => {
		let val = [...selected_business];
		const index = _.indexOf(val, id);
		if (index !== -1) {
			val.splice(index, 1);
		} else {
			val.push(id);
		}
		set_selected_business(val);
	};

	useEffect(() => {
		if (owner_type.id == constant.OWNER_TYPE.AGGREGATOR.id) {
			const all_business = _.map(enterprise_child_business_data, 'id');
			all_business.push(selected_business_account?.business_id);
			set_selected_business(all_business);
		} else {
			get_data();
		}
	}, []);

	useEffect(() => {
		if (is_initial_render.current) {
			is_initial_render.current = false;
			return;
		}
		if (owner_type.id == constant.OWNER_TYPE.AGGREGATOR.id) {
			get_data(selected_business);
		} else {
			get_data();
		}
	}, [filters, selected_menu]);

	useEffect(() => {
		if (!_.isEmpty(selected_business)) {
			get_data(selected_business);
			set_all_selected(is_all_selected());
		}
	}, [selected_business]);

	return (
		<>
			{(is_first_time_user || get_started_clicked) && (
				<FirstTimeOverlay
					onClose={() => {
						localStorage.setItem('new_user', false);
						set_get_started_clicked(false);
						set_is_first_time_user(false);
					}}
				/>
			)}

			{is_first_time_user && !_.isEmpty(coupon_data) && show_first_time_coupon_modal && (
				<CouponCode
					coupon={coupon_data}
					close={() => {
						set_show_first_time_coupon_modal(false);
					}}
				/>
			)}
			<div className={classes.main_container}>
				<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 32 }}>
					<Text className={classes.global_header_text} semi>
						Hi, {utils._retrieveData('name')}!
					</Text>
					{!_.isEmpty(coupon_data) && (
						<div className={classes.coupon_wrapper}>
							<img src={ImageLinks.nrai} width={28} height={28} />
							<Text
								medium
								className={
									classes.coupon_text
								}>{`Congratulations! As part of the NRAI program, we have added ₹${coupon_data.value} to your wallet. Enjoy the benefits!`}</Text>
							<Text semi className={classes.wallet_text} onClick={handle_wallet_click}>
								Wallet
							</Text>
							<img src={ImageLinks.arrow_right_purple} width={12} height={12} />
						</div>
					)}
					<div className='d-flex justify-content-center align-items-center' style={{ gap: 4 }}>
						{owner_type.id == constant.OWNER_TYPE.AGGREGATOR.id && (
							<div className='d-flex justify-content-center align-items-center' style={{ gap: 10 }}>
								<Select
									value={selected_business}
									defaultOption={{
										title: `Select Business`,
										value: '',
									}}
									options={_.map(
										[
											{ brand_name: selected_business_account?.brand_name, id: selected_business_account?.business_id },
											...enterprise_child_business_data,
										],
										(child) => {
											return {
												title: `${child.brand_name} (${child.id})`,
												value: child.id,
											};
										},
									)}
									handleChange={(v) => handle_select_drop_down(v)}
									containerStyles={{
										height: 36,
										width: 200,
										marginTop: 5,
										border: `1px solid ${theme.colors.lightPurple}`,
										boxShadow: '0px 2px 4px 0px rgba(196, 196, 196, 0.30)',
									}}
									text_props={{ semi: true }}
									menu_styles={{
										width: 180,
										height: 'fit-content',
									}}
									multiple={true}
									select_all={true}
									handleChange_select_all={handle_select_all}
									all_selected_check={all_selected}
									selected_text={'stores selected'}
								/>
							</div>
						)}
						<Button type='text' text='Get Started' onClick={get_start_tap} />
					</div>
				</div>
				<div style={{ overflow: 'auto', height: '85vh' }}>
					<Overview selected_menu={selected_menu} ids={selected_business} />
					<div className={classes.title_box}>
						<Text>
							<Text bold component={'span'}>
								{filters.to.diff(filters.from, 'days') + 1} Days Overview
							</Text>{' '}
							as per{' '}
							<div ref={menu_ref} className={classes.list_text} onClick={() => set_show_dropdown(!show_dropdown)}>
								<Text component={'span'} semi style={{ color: theme.colors.primary, fontSize: 14 }}>
									{_.get(dropdown_menu, `${selected_menu}.label`, '')}{' '}
								</Text>
								<img src={ImageLinks.chevronLeft} style={{ transform: 'rotate(270deg)' }}></img>
							</div>
						</Text>
						<Menu
							anchorEl={menu_ref.current}
							open={show_dropdown}
							PaperProps={{
								style: {
									borderRadius: 10,
									marginTop: 50,
									maxHeight: 400,
								},
							}}
							onClose={() => set_show_dropdown(false)}>
							{_.map(dropdown_menu, (item, index) => {
								return (
									<MenuItem
										sx={{ width: 100 }}
										value={item.value}
										onClick={(e) => {
											set_selected_menu(item.value);
											set_show_dropdown(false);
										}}
										key={`hour_menu_${index}`}>
										{item.label}
									</MenuItem>
								);
							})}
						</Menu>
						<div style={{ position: 'relative' }}>
							<div style={{ display: 'flex', gap: 10 }}>
								<div>
									<Text bold className={classes.date_text}>{`${filters.from.format('DD MMM `YY')} - ${filters.to.format(
										'DD MMM `YY',
									)}`}</Text>
								</div>
								<img src={ImageLinks.calendar_solid_circle} alt='date' onClick={() => set_date_picker_open(true)}></img>
							</div>
							<div style={{ position: 'absolute', right: 0, width: 700 }}>
								<DateRangePicker
									open={date_picker_open}
									toggle={() => set_date_picker_open(!date_picker_open)}
									style={{ width: 200 }}
									initialDateRange={{
										startDate: filters.from,
										endDate: filters.to,
									}}
									maxDate={new Date()}
									onChange={(range) => {
										const newDateRange = {
											from: moment(range.startDate),
											to: moment(range.endDate),
										};
										set_filters(newDateRange);
										set_date_picker_open(!date_picker_open);
									}}
								/>
							</div>
						</div>
					</div>
					<div className={classes.divider} style={{ margin: '10px 0' }}></div>
					<div className={classes.flex_box}>
						<div style={{ flex: 1 }}>
							<TotalOrders orders_data={_.get(data, 'stats.order', [])} component_loader={component_loader} />
						</div>
						<div style={{ flex: 1 }}>
							<ChannelMix channel_data={_.sortBy(_.get(data, 'stats.dd_channel', {}), ['_id'])} component_loader={component_loader} />
						</div>
						<div style={{ flex: 2 }}>
							<Revenue
								orders_data={_.get(data, 'stats.order', [])}
								revenue_data={_.get(data, 'stats.date', {})}
								component_loader={component_loader}
							/>
						</div>
					</div>
					{!(is_first_time_user || get_started_clicked) && (
						<div className={classes.flex_box} style={{ marginTop: 20 }}>
							<div style={{ flex: 3 }}>
								<OrderZones
									master_zone_data={_.get(data, 'stats.masterZone', {})}
									business_zone_data={_.get(data, 'stats.businessZone', {})}
									component_loader={component_loader}
								/>
							</div>
							<div style={{ flex: 1 }}>
								<DeliveryPartner partner_data={_.sortBy(_.get(data, 'stats.fulfillment', []), ['_id'])} component_loader={component_loader} />{' '}
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
}

export default Dashboard;
