import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { makeStyles, Modal } from '@material-ui/core';
import Text from 'modules/Text';
import theme from 'resources/theme';
import ImageLinks from 'assets/images/ImageLinks';
import Button from 'modules/Button';
import Select from 'modules/Select';
import { CATEGORY } from '../helper';
import OutlinedTextField from 'modules/OutlinedTextField';
import { toast } from 'react-toastify';
import Routes from 'resources/Routes';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
	modalContainer: { display: 'flex', outline: 'none' },
	componentContainer: {
		padding: 24,
		paddingBottom: 0,
		background: 'white',
		width: 600,
		margin: 'auto auto',
		borderRadius: 20,
		display: 'flex',
		flexDirection: 'column',
		outline: 'none',
	},
	headerTitle: { fontSize: 18, width: '100%', paddingTop: 5 },
	textContainer: { fontSize: 14, marginTop: 8 },
	containedButtonsContainer: { marginTop: 32, marginBottom: 24, display: 'flex', alignSelf: 'flex-end' },
	button: {
		'&:hover': {
			background: 'linear-gradient(180deg, #BC0000 0%, #620101 112.5%) !important',
			boxShadow: '0px 8px 20px 0px rgba(102, 102, 102, 0.15) !important',
			border: 'none !important',
		},
	},
}));

const CreateIssueModal = ({ close, style = {}, positive_btn_text = 'Confirm', is_error_msg = false, order_id }) => {
	const classes = useStyles();
	const history = useHistory();
	const [category, set_category] = useState('');
	const [description, set_description] = useState('');
	const [refund_amount, set_refund_amount] = useState();

	const handle_confirm = async () => {
		try {
			const response = await fetch('https://ondc-lbnp-preprd.pidge.in/ondc/order_data');
			const ondc_order_list = await response.json();
			const filtered_list = _.filter(ondc_order_list, (item) => item.order_id == order_id)[0];
			if (_.isEmpty(filtered_list)) {
				toast.error('Order not Place from ONDC', {
					position: toast.POSITION.TOP_CENTER,
				});
				return;
			}
			const payload = {
				category,
				description,
				refund_amount,
				txn_id: filtered_list?.txn_id,
				bpp_id: filtered_list?.bpp_id,
			};
			try {
				const res = await fetch(' https://ondc-lbnp-preprd.pidge.in/ondc/issue/order', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(payload),
				});
				if (res.ok) {
					close();
					history.push(Routes.IGM_ISSUE.path);
					toast.success('Ticket Raise Successfully', {
						position: toast.POSITION.TOP_CENTER,
					});
				}
			} catch (err) {
				toast.error(err.message, {
					position: toast.POSITION.TOP_CENTER,
				});
			}
		} catch (error) {
			toast.error(error.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
			});
		}
	};

	const btn_disabled = useMemo(() => {
		let val = true;
		if (category != '' && description != '') {
			val = false;
		}
		return val;
	}, [category, description, refund_amount]);
	return (
		<Modal open={true} onClose={close} className={classes.modalContainer}>
			<div className={classes.componentContainer} style={style}>
				<div style={{ display: 'flex' }}>
					<div style={{ marginLeft: 16, width: '100%' }}>
						<div style={{ display: 'flex' }}>
							<Text semi className={classes.headerTitle}>
								Raise Ticket
							</Text>
							<div style={{ cursor: 'pointer' }} onClick={close}>
								<img src={ImageLinks.crossBlack} style={{ width: 16, height: 16, paddingTop: 0 }} alt='' />
							</div>
						</div>
						<div className='d-flex' style={{ gap: 20, marginTop: 20, flexDirection: 'column' }}>
							<Select
								value={category}
								defaultOption={{
									title: `Select Ticket type`,
									value: '',
								}}
								options={CATEGORY}
								handleChange={(v) => set_category(v)}
								containerStyles={{
									height: 36,
									width: '80%',
									marginTop: 5,
									border: `1px solid ${theme.colors.lightPurple}`,
									boxShadow: '0px 2px 4px 0px rgba(196, 196, 196, 0.30)',
								}}
								text_props={{ semi: true }}
								menu_styles={{
									width: 250,
									height: 'fit-content',
								}}
							/>
							<OutlinedTextField
								value={description}
								type='text'
								multiline
								minRows={3}
								textStyle={{ fontSize: 16, color: theme.colors.black }}
								name='network_desc'
								onChange={(e) => {
									set_description(e.target.value);
								}}
								label='Description*'
								style={{ width: '80%', opacity: 1 }}
							/>
							<OutlinedTextField
								type='number'
								value={refund_amount}
								textStyle={{ fontSize: 16, color: theme.colors.black }}
								name='Refund amount'
								onChange={(e) => {
									set_refund_amount(e.target.value);
								}}
								label='Refund Amount'
								style={{ width: 235, opacity: 1 }}
							/>
						</div>
					</div>
				</div>

				<div className={classes.containedButtonsContainer}>
					<Button
						type='round'
						size='large'
						text={positive_btn_text}
						onClick={handle_confirm}
						className={is_error_msg ? classes.button : ''}
						disabled={btn_disabled}
						style={{
							// backgroundColor: is_error_msg ? theme.colors.red : theme.colors.primary,
							// borderColor: is_error_msg ? theme.colors.red : theme.colors.primary,
							minWidth: 110,
						}}
					/>
				</div>
			</div>
		</Modal>
	);
};

export default CreateIssueModal;
