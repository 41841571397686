import { makeStyles, useTheme } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import Text from 'modules/Text';
import React, { useContext, useMemo, useState } from 'react';
import OrderModalContext from '../OrderModalContext';
import OrderLabel from 'modules/OrderLabel';
import _ from 'lodash';
import constant from 'resources/constant';
import Button from 'modules/Button';
import { useHistory } from 'react-router-dom';
import Routes from 'resources/Routes';

const useStyles = makeStyles((theme) => ({
	container: {
		width: 'auto',
		flex: 2,
		display: 'flex',
		flexDirection: 'column',
		gap: 4,
	},
	divider: {
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
		margin: '10px 0',
	},
}));

function OrderHeader({ close }) {
	const history = useHistory();
	const classes = useStyles();
	const theme = useTheme();

	const { id, data, set_show_raise_issue, ondc_issue_list } = useContext(OrderModalContext);

	const show_view_btn = useMemo(() => {
		let value = false;
		value = _.some(ondc_issue_list, { order_id: data?.dd_channel?.order_id });
		return value;
	}, [ondc_issue_list, data]);
	return (
		<div className={classes.container}>
			<div className='d-flex justify-content-between'>
				<div className='d-flex justify-content-between' style={{ gap: 10 }}>
					<div>
						<Text semi style={{ fontSize: 18 }}>
							{data.reference_id || data?.dd_channel?.order_id || data.id || '-'}
						</Text>
						<Text semi style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
							Channel/ REF ID
						</Text>
					</div>
					<div style={{ height: 26 }}>
						{(data.status == 0 || _.get(data, 'fulfillment.status', null)) && (
							<OrderLabel
								status={data.status == 0 ? 0 : constant.ORDER_STATUS[_.get(data, 'fulfillment.status', null)]?.id}
								paid={_.get(data, 'fulfillment_pricing.paid', false)}
							/>
						)}
					</div>
				</div>

				<div className='d-flex align-items-center' style={{ gap: 10 }}>
					<img src={ImageLinks.download_circle} alt='' />
					<img src={ImageLinks.crossBlack} onClick={close} style={{ cursor: 'pointer' }} alt='' />
				</div>
			</div>
			{data?.dd_channel?.name == 'ondc' && (
				<div style={{ height: 26 }}>
					{show_view_btn ? (
						<Button type='text' text='View Ticket' size='small' onClick={() => history.push(Routes.IGM_ISSUE.path)} style={{ width: '25%' }} />
					) : (
						<Button type='text' text='Raise Ticket' size='small' onClick={() => set_show_raise_issue(true)} style={{ width: '25%' }} />
					)}
				</div>
			)}
			<div className={classes.divider}></div>
		</div>
	);
}

export default OrderHeader;
