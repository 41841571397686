export const STATUS_OPTION = [
	{ title: 'All', value: 'All' },
	{ title: 'Closed', value: 'CLOSED' },
	{ title: 'Open', value: 'OPEN' },
];

export const CATEGORY = [
	{ title: 'Delivery delayed', value: 'FLM01' },
	{ title: 'Delivery late', value: 'FLM02' },
	{ title: 'Spillage / Improper packaging', value: 'FLM04' },
];
